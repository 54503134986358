import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { getResources } from '@cms/resources';
import Layout from '@components/Layout/Layout';
import Header from '@components/Header/Header';
import Hero from '@components/PageComponents/Hero/Hero';
import { SectionContainer, Section } from '@components/Section';
import CallToAction from '@components/PageComponents/CallToAction/CallToAction';
import Link from '@components/Link';
import Styles from './Resources.module.scss';

const fallbacks = {
  'digital-marketers': 'digital-marketing',
  developers: 'development',
  designers: 'design',
  writers: 'copywriting',
  'project-managers': 'project-management',
  multimedia: 'designers',
};

const slugify = (str) =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');
const ResourceLink = ({ label, slug }) => {
  return (
    <Link className={Styles.link} href={slug}>
      {label}
    </Link>
  );
};

const ResourceCard = ({ resource }) => {
  return (
    <div className={Styles.card}>
      <div className={Styles.row} style={{ marginBottom: '16px' }}>
        <h2 className={Styles.resourceTitle}>{resource.role}</h2>
        <p className={Styles.resourceLabel}>{resource.talentType}</p>
      </div>
      <p className={Styles.resourceDescription}>{resource.description}</p>

      <div className={Styles.resourceRow}>
        {resource.jobDescriptions.map((job, ind) => (
          <ResourceLink key={ind} label={`Job Description`} slug={`job-descriptions/${job.slug}`} />
        ))}
        {resource.hiringGuides.map((guide, ind) => (
          <ResourceLink key={ind} label={`Hiring Guide `} slug={`${guide}`} />
        ))}
        {resource.interviewQuestions.map((quest, ind) => (
          <ResourceLink key={ind} label={`Interview Questions `} slug={`${quest}`} />
        ))}
        {resource.talentProfiles.map((talent, ind) => (
          <ResourceLink key={ind} label={`Talent Profiles `} slug={`${talent.slug}`} />
        ))}
      </div>
    </div>
  );
};

const ResourcePage = ({ resources, talentTypes }) => {
  const router = useRouter();
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const head = {
    title: `Resources | Hire Digital`,
    meta: [
      {
        name: 'description',
        content: `A complete guide to hiring multimedia talent. Job description templates, interview questions, hiring guides, and talent profiles to help you hire the best talent for your team. Whether it's full-time, part-time, or freelance. Hire Digital helps businesses to engage a freelance network of the best multimedia talents on demand.`,
      },
    ],
  };

  useEffect(() => {
    if (router?.query.tab && !isLoaded) {
      setSelectedFilter(fallbacks[router?.query.tab] || router?.query.tab);
    }
  }, [router.query, isLoaded]);

  const filterByToggle = (a, b) => {
    return true;
  };

  const selectType = (type) => {
    setIsLoaded(true);
    setSelectedFilter(type?.id || null);
    if (type) {
      router.push(`/resources?tab=${type.id}`);
    } else {
      router.push(`/resources`);
    }
  };

  // const handleSearch = (e) => {
  //   const { name, value } = e.target;
  //   console.log(value);
  // };

  return (
    <Layout header={Header} pageHead={head}>
      <Hero parent={Hero.Page.NEW_HERO}>
        <div className={classNames(Hero.Styles.heroContainer, Styles.hero)}>
          <h1 className={classNames(Hero.Styles.heroTitle, Styles.title)}>Hiring Resources</h1>
        </div>
      </Hero>

      <SectionContainer type={SectionContainer.Type.LIGHTBLUE}>
        <Section>
          {/*          <div className={Styles.searchContainer}>
            <input name={`search`} className={Styles.searchInput} onChange={handleSearch} />
          </div>
*/}
          <div className={Styles.talentRowContainer}>
            <div className={Styles.talentRow}>
              <div
                className={classNames(
                  Styles.talentSelector,
                  selectedFilter === null && Styles.selected
                )}
                onClick={() => selectType(null)}>
                All
              </div>
              {talentTypes.map((talentType, ind) => (
                <div
                  className={classNames(
                    Styles.talentSelector,
                    talentType.id === selectedFilter && Styles.selected
                  )}
                  key={ind}
                  onClick={() => selectType(talentType)}>
                  {talentType.label}
                </div>
              ))}
            </div>
          </div>

          <div className={Styles.cardContainer}>
            {/*{talentTypes.map((talentType, ind) => retu)}*/}
            {resources
              ?.filter((a) => slugify(a.talentType) == selectedFilter || selectedFilter == null)
              .map((resource, ind) => {
                return <ResourceCard key={ind} resource={resource} />;
              })}
          </div>
        </Section>
      </SectionContainer>
      <CallToAction />
    </Layout>
  );
};

export default ResourcePage;

export const getStaticProps = async ({ params, preview }) => {
  const { resources } = await getResources(preview);
  if (!resources) {
    return {
      notFound: true,
      revalidate: 500,
    };
  }

  // mapping

  const talentTypes = [...new Set(resources.map((r) => r.talentType))];

  const updatedTalentTypes = talentTypes.map((type) => {
    return { id: slugify(type), label: type };
  });

  return {
    props: { resources, talentTypes: updatedTalentTypes },
    revalidate: 500,
  };
};
